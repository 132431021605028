import { useEffect, useState } from "react";
import PubNub from "pubnub";
import useDraftStore from "../../lib/store/DraftStore";
import { fetchAndUpdateDraftData } from "../../lib/utils/draftStoreUtils";

export function usePubNub({ boardId, userId, env }) {
  const [pubnub] = useState(
    new PubNub({
      subscribeKey: "sub-c-a6d298a7-6939-4d94-ad6e-f788e32a47b8",
      userId: userId.toString(),
    }),
  );
  const [connectionStatus, setConnectionStatus] = useState("connecting");
  const [error, setError] = useState(null);
  const [showPickNotification, setShowPickNotification] = useState(false);
  const [pickMessage, setPickMessage] = useState("");
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const boardChannel = "board-" + boardId + "-" + env;
    let reconnectTimeout = null;
    let retryCount = 0;
    const MAX_RETRIES = 5;
    const RETRY_DELAY = 2000;
    const FETCH_INTERVAL = 1 * 60 * 1000;

    const subscribeToChannel = () => {
      console.log("Subscribing to channel:", boardChannel);
      pubnub.subscribe({
        channels: [boardChannel],
        withPresence: true,
      });
    };

    const unsubscribeFromChannel = () => {
      console.log("Unsubscribing from channel:", boardChannel);
      pubnub.unsubscribe({
        channels: [boardChannel],
      });
    };

    const attemptReconnect = () => {
      if (retryCount >= MAX_RETRIES) {
        setError(
          "Failed to reconnect after multiple attempts. Please refresh the page.",
        );
        return;
      }

      const delay = RETRY_DELAY * Math.pow(2, retryCount);
      retryCount++;

      clearTimeout(reconnectTimeout);
      reconnectTimeout = setTimeout(() => {
        setConnectionStatus("connecting");
        setError(
          `Attempting to reconnect... (Attempt ${retryCount}/${MAX_RETRIES})`,
        );

        unsubscribeFromChannel();
        pubnub.reconnect();
        subscribeToChannel();
      }, delay);
    };

    const handleRefresh = async () => {
      try {
        await fetchAndUpdateDraftData(boardId);
      } catch (error) {
        console.error("Failed to refresh draft data:", error);
      }
    };

    const handlePickMade = (data) => {
      const [, pickId, playerId, isAutopick] = data.split("|");
      useDraftStore.getState().updatePick(pickId, playerId, isAutopick);

      // setPickMessage(
      //   `Pick ${pickId}: Player ${playerId} selected${isAutopick ? " (Auto-pick)" : ""}`,
      // );
      // setShowPickNotification(true);
    };

    const handleCurrentlyDrafting = (data) => {
      const parts = data.split("|");
      const pickId = parts[1];
      const secondsRemaining = parseFloat(parts[3]);

      // Calculate expiration time based on client's clock + seconds remaining
      const clientTime = new Date();
      const clientExpiresAt = new Date(
        clientTime.getTime() + secondsRemaining * 1000,
      );

      // Store the client-calculated expiration time
      useDraftStore.getState().setCurrentPickId(parseInt(pickId));
      useDraftStore.getState().setPickExpiresAt(clientExpiresAt.toISOString());

      console.log(
        `Pick expires in ${secondsRemaining} seconds (at ${clientExpiresAt.toISOString()})`,
      );
    };

    const handleStatusChange = (data) => {
      const [, status] = data.split("|");
      useDraftStore.getState().setDraftStatus(status);
    };

    const handleAutopicking = (data) => {
      const [, teamIdsString] = data.split("|");
      const teamIds = teamIdsString.split(",").map((id) => parseInt(id));

      useDraftStore.getState().setAutopicking(teamIds);
    };

    const handleMessage = (event) => {
      const message = event.message;
      console.log("Received message:", message);

      if (typeof message !== "string") return;

      const messageHandlers = {
        P: handlePickMade,
        D: handleCurrentlyDrafting,
        S: handleStatusChange,
        X: handleRefresh,
        A: handleAutopicking,
      };

      const messageType = message.charAt(0);
      const handler = messageHandlers[messageType];

      if (handler) {
        handler(message);
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        if (connectionStatus !== "connected") {
          console.log(
            "Tab became visible and not connected, attempting reconnection",
          );
          setConnectionStatus("connecting");
          unsubscribeFromChannel();
          pubnub.reconnect();
          subscribeToChannel();
        } else {
          console.log(
            "Tab became visible but already connected, skipping reconnection",
          );

          const lastFetchTime = useDraftStore.getState().lastFetchTime;
          const timeSinceLastFetch = Date.now() - lastFetchTime;
          if (timeSinceLastFetch >= FETCH_INTERVAL) {
            console.log(
              "More than 2 minutes since last fetch, refreshing data",
            );
            handleRefresh();
          }
        }
      }
    };

    const listener = {
      message: handleMessage,
      status: async (statusEvent) => {
        console.log("PubNub status:", statusEvent.category);

        switch (statusEvent.category) {
          case "PNConnectedCategory":
            retryCount = 0;
            setConnectionStatus("connected");
            setError(null);
            await handleRefresh();
            break;

          case "PNReconnectedCategory":
            retryCount = 0;
            setConnectionStatus("connected");
            setError(null);
            setShowSuccessNotification(true);
            setSuccessMessage("Reconnected to draft room");
            await handleRefresh();
            break;

          case "PNNetworkIssuesCategory":
          case "PNTimeoutCategory":
            setConnectionStatus("error");
            setError("Network connectivity issues detected");
            attemptReconnect();
            break;

          case "PNNetworkDownCategory":
            setConnectionStatus("error");
            setError("Network appears to be offline");
            attemptReconnect();
            break;

          case "PNNetworkUpCategory":
            retryCount = 0;
            setConnectionStatus("connecting");
            setError(null);
            unsubscribeFromChannel();
            pubnub.reconnect();
            subscribeToChannel();
            setShowSuccessNotification(true);
            setSuccessMessage("Reconnected to draft room");
            break;

          case "PNUnexpectedDisconnectCategory":
            setConnectionStatus("disconnected");
            setError("Unexpected disconnection. Attempting to reconnect...");
            attemptReconnect();
            break;

          case "PNAccessDeniedCategory":
            setConnectionStatus("error");
            setError("Access denied. Please check your subscription key.");
            break;
        }
      },
    };

    pubnub.addListener(listener);
    subscribeToChannel();
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearTimeout(reconnectTimeout);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      pubnub.removeListener(listener);
      unsubscribeFromChannel();
    };
  }, [boardId, env, pubnub, connectionStatus]);

  return {
    pubnub,
    connectionStatus,
    error,
    showPickNotification,
    pickMessage,
    setShowPickNotification,
    showSuccessNotification,
    successMessage,
    setShowSuccessNotification,
  };
}
