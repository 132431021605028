import React from "react";
import { UpcomingStrip } from "../UpcomingStrip";
import { OnTheClock } from "../OnTheClock";
import { PicksGrid } from "../board/PicksGrid";
import { StatusBar } from "../StatusBar";

export function TopPanel() {
  return (
    <div className="flex h-full">
      <StatusBar />
      <div className="flex-1 flex flex-col w-1/2 xl:w-full overflow-y-auto">
        <div
          id="top-bar"
          className="flex sticky left-0 flex-row overflow-x-clip"
        >
          <OnTheClock />
          <UpcomingStrip />
        </div>

        <div id="board-container" className="mt-1 px-0 flex-1">
          <PicksGrid />
        </div>
      </div>
    </div>
  );
}
