import React from "react";
import { PlayerTable } from "./table/PlayerTable";
import useDraftStore from "../lib/store/DraftStore";
import { CloseIcon, SearchIcon } from "./CloseIcon";

function SearchBar({ onSearch }) {
  const [searchQuery, setSearchQuery] = useDraftStore((state) => [
    state.searchQuery,
    state.setSearchQuery,
  ]);

  function handleChange(e) {
    const value = e.target.value;
    setSearchQuery(value);
  }

  function handleClear() {
    setSearchQuery("");
  }

  return (
    <div className="relative min-w-52 overflow-clip font-light border rounded md:rounded-full bg-white border-gray-200 focus-within:border-primary-500 md:max-w-80 dark:bg-gray-800 dark:border-gray-700 dark:focus-within:border-gray-500">
      <SearchIcon />
      <input
        className="h-12 md:h-10 w-full border-0 bg-transparent pl-11 pr-9 text-gray-900 placeholder:text-gray-500 focus:outline-none focus:ring-0 sm:text-md dark:text-white dark:placeholder:text-gray-400"
        placeholder="Search for player"
        autoComplete="off"
        autoCorrect="off"
        value={searchQuery}
        onChange={handleChange}
      />
      {searchQuery && (
        <button
          className="absolute bottom-0 right-2 top-0 text-gray-600"
          onClick={handleClear}
        >
          <CloseIcon />
        </button>
      )}
    </div>
  );
}

function FilterDropdown({ label, isActive, onClick }) {
  return (
    <button
      onClick={onClick}
      className={`inline-flex items-center whitespace-nowrap rounded-full border px-3 py-2 text-sm font-medium transition-colors focus:outline-none sm:px-4 sm:py-2 ${
        isActive
          ? "border-primary-500 bg-primary-500 text-white dark:bg-primary-600"
          : "border-gray-300 bg-white text-gray-900 hover:border-primary-500 dark:border-gray-600 dark:bg-transparent dark:text-white dark:hover:border-gray-400 dark:hover:bg-gray-800"
      }`}
    >
      {label}
    </button>
  );
}

function Checkbox({ label, checked, onChange }) {
  return (
    <label className="flex items-center space-x-2 px-1">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="w-5.5 h-5.5 text-xl rounded-full text-primary-600 bg-gray-100 border-gray-300 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-600 dark:ring-offset-gray-800 focus-visible:ring-2 focus:ring-primary-500 focus:dark:ring-offset-gray-800 dark:bg-gray-600 dark:border-gray-500"
      />
      <span className="text-xs font-light leading-tight text-gray-700 dark:text-gray-200">
        {label}
      </span>
    </label>
  );
}

export function PlayersTab() {
  const [
    players,
    draftedPlayerIds,
    searchQuery,
    selectedPosition,
    setSelectedPosition,
    rookiesOnly,
    setRookiesOnly,
    showDrafted,
    setShowDrafted,
    showQueueOnly,
    setShowQueueOnly,
    hideLongTermInjuries,
    setHideLongTermInjuries,
    queueIds,
    leagueData,
  ] = useDraftStore((state) => [
    state.players,
    state.draftedPlayerIds,
    state.searchQuery,
    state.selectedPosition,
    state.setSelectedPosition,
    state.rookiesOnly,
    state.setRookiesOnly,
    state.showDrafted,
    state.setShowDrafted,
    state.showQueueOnly,
    state.setShowQueueOnly,
    state.hideLongTermInjuries,
    state.setHideLongTermInjuries,
    state.queueIds,
    state.leagueData,
  ]);

  const positions = leagueData.isKeyPos
    ? ["All", "KEYD", "DEF", "MID", "RUC", "FWD", "KEYF"]
    : ["All", "DEF", "MID", "RUC", "FWD"];

  const filteredPlayers = players.filter((player) => {
    // Temp hide drafted
    if (draftedPlayerIds.includes(player.id)) return false;

    // If there's a search query, it takes priority over all other filters
    if (searchQuery) {
      return player.name.toLowerCase().includes(searchQuery.toLowerCase());
    }

    // If no search query, apply other filters
    // Filter out drafted players unless showDrafted is true
    if (!showDrafted && draftedPlayerIds.includes(player.id)) return false;
    if (showQueueOnly && !queueIds.includes(player.id)) return false;

    // Apply rookies filter
    if (rookiesOnly && player.career.seasonsList !== 1) return false;

    // Apply long term injuries filter
    if (hideLongTermInjuries && ["2", "4", "9"].includes(player.injuryStatus))
      return false;

    // Apply position filter
    if (selectedPosition && selectedPosition !== "All") {
      return player.positions.some((pos) => pos === selectedPosition);
    }

    return true;
  });

  return (
    <>
      <div className="flex md:sticky px-2 mt-4 md:top-0 gap-0.5 md:gap-4 flex-col md:flex-row md:no-scrollbar md:overflow-x-auto">
        <SearchBar />

        <div className="mt-2 md:mt-0 flex items-center justify-between">
          <div
            className={`no-scrollbar -mx-2 flex items-center overflow-auto space-x-1 px-2 ${searchQuery ? "opacity-30" : ""}`}
          >
            {positions.map((pos) => (
              <FilterDropdown
                key={pos}
                label={pos}
                isActive={selectedPosition === pos}
                onClick={() => setSelectedPosition(pos)}
              />
            ))}
            <Checkbox
              label="My Queue"
              checked={showQueueOnly}
              onChange={(e) => setShowQueueOnly(e.target.checked)}
            />
            {/*<Checkbox*/}
            {/*  label="Show Drafted"*/}
            {/*  checked={showDrafted}*/}
            {/*  onChange={(e) => setShowDrafted(e.target.checked)}*/}
            {/*/>*/}
            <Checkbox
              label="Rookies Only"
              checked={rookiesOnly}
              onChange={(e) => setRookiesOnly(e.target.checked)}
            />
            <Checkbox
              label="Hide LTIs"
              checked={hideLongTermInjuries}
              onChange={(e) => setHideLongTermInjuries(e.target.checked)}
            />
          </div>
        </div>
      </div>

      <div className="mt-4 px-2 md:px-0 md:flex-1 md:overflow-auto">
        <PlayerTable players={filteredPlayers} />
      </div>
    </>
  );
}
