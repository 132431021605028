import React from "react";
import { getTeamById } from "../../global/lib/utils/teams";
import { getPlayerPositionLabel } from "../../global/lib/utils/playerPositions";
import useDraftStore from "../lib/store/DraftStore";
import {
  allocatePlayers,
  playersByEligiblePosition,
  sortKeysByArrayLength,
  sortPositionsByDisplayOrder,
} from "../lib/utils/formationUtils";
import { getFormationPosByAbbr } from "../../global/lib/utils/formationPositions";

export default function TeamLineup({ teamId }) {
  const [players, picks, leagueSeasonId, keptPlayerIdsByTeamId, leagueData] =
    useDraftStore((state) => [
      state.players,
      state.picks,
      state.leagueSeasonId,
      state.keptPlayerIdsByTeamId,
      state.leagueData,
    ]);

  const filteredPicks = picks.filter(
    (pick) => pick.teamId === parseInt(teamId),
  );

  const draftedIds = filteredPicks
    .map((pick) => pick.playerId)
    .filter((playerId) => playerId !== null);

  const keeperIds = keptPlayerIdsByTeamId[teamId] || [];
  const allPlayerIds = [...keeperIds, ...draftedIds];

  const teamPlayers = players.filter((player) =>
    allPlayerIds.includes(player.id),
  );

  if (leagueData.scoringType === "Categories") {
    teamPlayers.sort((a, b) => {
      // Handle cases where ranks is null
      if (!a.ranks && !b.ranks) return 0;
      if (!a.ranks) return 1; // null ranks go to the end
      if (!b.ranks) return -1;

      return a.ranks.average - b.ranks.average;
    });
  } else {
    teamPlayers.sort((a, b) => {
      // Handle cases where points is null
      if (!a.points && !b.points) return 0;
      if (!a.points) return 1; // null points go to the end
      if (!b.points) return -1;

      return b.points.proj - a.points.proj;
    });
  }

  const playersByPosition = playersByEligiblePosition(
    leagueData.formation,
    teamPlayers,
  );

  const allocationOrder = sortKeysByArrayLength(playersByPosition);

  const allocatedPlayers = allocatePlayers(
    teamPlayers,
    allocationOrder,
    leagueData.formation,
  );

  // Sort starters and emergencies by display order
  const sortedStarters = sortPositionsByDisplayOrder(
    leagueData.formation.starters,
  );
  const sortedEmergencies = sortPositionsByDisplayOrder(
    leagueData.formation.emgs,
  );

  // Find pick number for a player
  const getPickNumber = (playerId) => {
    const pick = filteredPicks.find((pick) => pick.playerId === playerId);
    return pick ? pick.pickNumber : null;
  };

  return (
    <div className="divide-y dark:divide-gray-700 border-b dark:border-gray-700">
      {/* Starters section */}
      {/*<div className="sticky top-0 z-10 py-1 inline-flex w-full items-center justify-center">*/}
      {/*  <span className="text-sm text-white rounded-full bg-emerald-500 px-3.5 py-1 font-medium dark:bg-gray-700 dark:text-gray-200">*/}
      {/*    STARTERS*/}
      {/*  </span>*/}
      {/*</div>*/}

      {sortedStarters.map((pos) => (
        <React.Fragment key={pos.abbr}>
          <div className="flex items-center justify-between dark:bg-gray-800 px-4 py-1 font-medium text-gray-600 dark:text-gray-400 tracking-tight text-xxs">
            <span>{getFormationPosByAbbr(pos.abbr).pluralName}</span>
            <span>Pick</span>
          </div>
          {Array.from({ length: pos.limit }, (_, index) => {
            const player = allocatedPlayers.starters[pos.abbr]?.[index] || null;
            return (
              <LineupRow
                key={`${pos.abbr}-${index}`}
                position={pos.abbr}
                playerId={player?.id}
                isStarting={true}
                teamId={teamId}
                leagueSeasonId={leagueSeasonId}
                pickNumber={getPickNumber(player?.id)}
              />
            );
          })}
        </React.Fragment>
      ))}

      {/* Emergencies section */}
      <div className="sticky top-14 py-1 z-10 inline-flex w-full items-center justify-center">
        <span className="text-sm text-white rounded-full bg-gray-500 px-4.5 py-1 font-medium dark:bg-gray-700 dark:text-gray-200">
          EMERGENCIES
        </span>
      </div>

      {sortedEmergencies.map((pos) => {
        return Array.from({ length: pos.limit }, (_, index) => {
          const player =
            allocatedPlayers.emergencies[pos.abbr]?.[index] || null;
          return (
            <LineupRow
              key={`${pos.abbr}-EMG-${index}`}
              position={pos.abbr}
              playerId={player?.id}
              isStarting={false}
              teamId={teamId}
              leagueSeasonId={leagueSeasonId}
              pickNumber={getPickNumber(player?.id)}
            />
          );
        });
      })}

      {/* Bench section */}
      <div className="sticky top-14 py-1 z-10 inline-flex w-full items-center justify-center">
        <span className="text-sm text-white rounded-full bg-gray-500 px-8 py-1 font-medium dark:bg-gray-700 dark:text-gray-200">
          BENCH
        </span>
      </div>

      {allocatedPlayers.bench.map((player) => (
        <LineupRow
          key={`BENCH-${player.id}`}
          position="BN"
          playerId={player.id}
          isStarting={false}
          teamId={teamId}
          leagueSeasonId={leagueSeasonId}
          pickNumber={getPickNumber(player.id)}
        />
      ))}
    </div>
  );
}

function LineupRow({ playerId, position, teamId, leagueSeasonId }) {
  const [players, picks, leagueData] = useDraftStore((state) => [
    state.players,
    state.picks,
    state.leagueData,
  ]);

  const pick = picks.find((pick) => pick.playerId === playerId);
  const pickNumber = pick?.overallPick || "K";
  const isCategories = leagueData.scoringType === "Categories";

  const player = players.find((player) => player.id === parseInt(playerId));
  const playerTeam = player ? getTeamById(player.teamId) : null;

  return (
    <div className="flex items-center px-4 py-1.5 overflow-clip gap-2 bg-white dark:bg-gray-900">
      {/* Position */}
      <span className="text-gray-700 dark:text-gray-400 text-left text-xs font-medium w-10">
        {position}
      </span>

      {player ? (
        <>
          {/* Player Jersey */}
          <div className="overflow relative -mb-2.5 mr-2.5 flex h-10 w-8 items-start justify-center overflow-clip dark:brightness-125 dark:saturate-[0.8]">
            <img
              className="w-full"
              src={`/build/images/sport/teams/guernseys/${playerTeam?.abbr}.png`}
              alt={`${playerTeam?.name} guernsey`}
            />
          </div>

          {/* Player Name and Details */}
          <div className="min-w-0 flex-1">
            <div className="flex items-center">
              <a
                className="text-md font-medium dark:text-gray-100 md:hover:underline"
                data-turbo-frame="modal"
                href={`/afl/${leagueSeasonId}/players/profile/${player.id}`}
              >
                {player.abbrName}
              </a>
              <span className="ml-1 text-xs font-medium text-gray-800 dark:text-gray-200">
                {getPlayerPositionLabel(player.positions)}
              </span>
              {player.injuryStatus !== "0" && (
                <span
                  data-tooltip={player.injuryDesc}
                  className="ml-1.5 inline-flex items-center justify-center rounded-full bg-red-600 px-1 pb-0.75 pt-1 text-xxxxs font-semibold uppercase leading-none tracking-tight text-white dark:bg-red-700"
                >
                  {player.injuryAbbr}
                </span>
              )}
            </div>
            <div className="text-xs font-normal text-gray-700 dark:text-gray-400">
              {isCategories
                ? "ADP: " + (player.trends?.adp || "-")
                : "Proj: " + (player.points.proj?.toFixed(1) || "-")}
            </div>
          </div>

          {/* Pick Number */}
          {pickNumber && (
            <div className="text-sm text-gray-600 dark:text-gray-400 text-right">
              {pickNumber}
            </div>
          )}
        </>
      ) : (
        <div className="flex-1 text-gray-500 dark:text-gray-400 text-sm">
          - empty -
        </div>
      )}
    </div>
  );
}
