import React from "react";
import { DraftAPI } from "../../lib/utils/api";
import { fetchAndUpdateDraftData } from "../../lib/utils/draftStoreUtils";
import useDraftStore from "../../lib/store/DraftStore";

export function DraftButton({ playerId, playerName }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [
    currentPickId,
    onTheClock,
    pickExpiresAt,
    boardId,
    draftStatus,
    boardData,
    leagueData,
  ] = useDraftStore((state) => [
    state.currentPickId,
    state.onTheClock,
    state.pickExpiresAt,
    state.boardId,
    state.draftStatus,
    state.boardData,
    state.leagueData,
  ]);

  const canDraft =
    draftStatus === "InProgress" &&
    (onTheClock || (boardData?.singleCpu && leagueData?.isCommish));

  const getRemainingTime = () => {
    if (!pickExpiresAt) return 0;
    return Math.max(0, (new Date(pickExpiresAt) - new Date()) / 1000);
  };

  async function draftPlayer() {
    const timeLeft = getRemainingTime();
    const shouldConfirm = timeLeft > 10;

    if (
      !shouldConfirm ||
      confirm("Are you sure you want to select " + playerName)
    ) {
      setIsLoading(true);
      try {
        await DraftAPI.selectPlayer(currentPickId, playerId);
      } catch (error) {
        console.error("Error selecting player:", error);
        alert("Failed to select player. Please try again.");
        fetchAndUpdateDraftData(boardId);
      } finally {
        // setIsLoading(false);
      }
    }
  }

  return (
    <button
      onClick={draftPlayer}
      disabled={!canDraft || isLoading}
      className={`w-16 h-8 -my-0.5 flex items-center justify-center dark:text-white dark:border-gray-600 rounded-full font-semibold text-sm uppercase ${
        !canDraft || isLoading
          ? "pointer-events-none bg-none border text-gray-600 opacity-50"
          : "bg-blue-500 text-white transition-all pointer-events-auto"
      }`}
    >
      {isLoading ? (
        <svg className="h-4 w-4 animate-spin" viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
            fill="none"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      ) : (
        "Draft"
      )}
    </button>
  );
}
