import React, { useState } from "react";
import useLineupStore from "../../lib/store/LineupStore";
import { dispatchLineupEvent } from "./LineupButtonUtils";
import { getCurrentRole } from "./CaptainUtils";

function CaptainButtons({ playerId }) {
  const [captainId, viceId, deputyId, numCaptains, players] = useLineupStore(
    (state) => [
      state.captainId,
      state.viceId,
      state.deputyId,
      state.numCaptains,
      state.players,
    ],
  );

  const isCaptain = captainId === playerId;
  const isVice = viceId === playerId;
  const isDeputy = deputyId === playerId;
  const lineupEditable = true;

  return (
    <div className="flex items-center">
      {numCaptains >= 1 && (isCaptain || lineupEditable) && (
        <CaptainButton type="C" active={isCaptain} playerId={playerId} />
      )}

      {numCaptains >= 2 && (isVice || lineupEditable) && (
        <CaptainButton type="VC" active={isVice} playerId={playerId} />
      )}

      {numCaptains >= 3 && (isDeputy || lineupEditable) && (
        <CaptainButton type="DVC" active={isDeputy} playerId={playerId} />
      )}
    </div>
  );
}

function CaptainButton({ type, active, playerId }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [
    captainId,
    viceId,
    deputyId,
    setNewCaptainId,
    setNewViceId,
    setNewDeputyId,
    isSwapping,
    setIsSwapping,
    players,
    selectedPlayerId,
  ] = useLineupStore((state) => [
    state.captainId,
    state.viceId,
    state.deputyId,
    state.setNewCaptainId,
    state.setNewViceId,
    state.setNewDeputyId,
    state.isSwapping,
    state.setIsSwapping,
    state.players,
    state.selectedPlayerId,
  ]);

  const playerName = players.find((p) => p.id === playerId)?.name || "Player";

  const roleNames = {
    C: "Captain",
    VC: "Vice Captain",
    DVC: "Deputy Vice Captain",
  };

  async function handleClick() {
    setIsProcessing(true);
    setIsSwapping(true);
    const currentRole = getCurrentRole(playerId, captainId, viceId, deputyId);

    switch (type) {
      case "C":
        if (currentRole === "VC") {
          setNewViceId(captainId);
        } else if (currentRole === "DVC") {
          setNewDeputyId(captainId);
        }
        setNewCaptainId(playerId);
        break;

      case "VC":
        if (currentRole === "C") {
          setNewCaptainId(viceId);
        } else if (currentRole === "DVC") {
          setNewDeputyId(viceId);
        }
        setNewViceId(playerId);
        break;

      case "DVC":
        if (currentRole === "C") {
          setNewCaptainId(deputyId);
        } else if (currentRole === "VC") {
          setNewViceId(deputyId);
        }
        setNewDeputyId(playerId);
        break;
    }

    dispatchLineupEvent();
  }

  return (
    <button
      onClick={handleClick}
      disabled={(isSwapping && !isProcessing) || selectedPlayerId}
      aria-label={`Set ${playerName} as ${roleNames[type]}`}
      aria-busy={isProcessing}
      aria-pressed={active}
      className={`
          pointer-events-auto -my-0.5 ml-1 mr-0.5 flex h-8 w-8 items-center justify-center 
          rounded-full text-xxs font-semibold uppercase transition-all
          ${(isSwapping && !isProcessing) || selectedPlayerId ? "opacity-60" : ""}
          ${
            active || isProcessing
              ? "bg-purple-600 text-white"
              : "border bg-none text-gray-600 opacity-60 dark:border-gray-600 dark:text-white"
          }
        `}
    >
      {isProcessing ? (
        <svg
          className="h-4 w-4 animate-spin"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
            fill="none"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      ) : (
        type
      )}
    </button>
  );
}

export default CaptainButtons;
