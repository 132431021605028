import React, { useState } from "react";

function PanelControls({
  onExpand,
  onShrink,
  isFullyExpanded,
  isFullyCollapsed,
}) {
  return (
    <div className="absolute right-4 -top-10 z-50 flex gap-1">
      <button
        onClick={onExpand}
        disabled={isFullyExpanded}
        className="h-9 w-9 rounded-full transition-all hover:scale-105 bg-gray-600/70 dark:bg-gray-500/70 hover:bg-gray-700/70 dark:hover:bg-gray-400/70 disabled:opacity-50 disabled:cursor-not-allowed shadow-lg text-white flex items-center justify-center"
      >
        ↑
      </button>
      <button
        onClick={onShrink}
        disabled={isFullyCollapsed}
        className="h-9 w-9 rounded-full transition-all hover:scale-105 bg-gray-600/70 dark:bg-gray-500/70 hover:bg-gray-700/70 dark:hover:bg-gray-400/70 disabled:opacity-50 disabled:cursor-not-allowed shadow-lg text-white flex items-center justify-center"
      >
        ↓
      </button>
    </div>
  );
}

export function ResizablePanels({ TopContent, BottomContent }) {
  const STATES = {
    FULL: "full",
    HALF: "half",
    LOW: "low",
  };

  const [bottomState, setBottomState] = useState(STATES.HALF);
  const [isTransitioning, setIsTransitioning] = useState(false);

  function getHeights() {
    switch (bottomState) {
      case STATES.FULL:
        return {
          top: "h-[150px]",
          bottom: "h-[calc(100vh-150px)]",
        };
      case STATES.HALF:
        return {
          top: "h-[50vh]",
          bottom: "h-[50vh]",
        };
      case STATES.LOW:
        return {
          top: "h-[100vh]",
          bottom: "h-[0vh]",
        };
      default:
        return {
          top: "h-[50vh]",
          bottom: "h-[50vh]",
        };
    }
  }

  function handleTransition(callback) {
    setIsTransitioning(true);
    callback();
    setTimeout(() => {
      setIsTransitioning(false);
    }, 500);
  }

  function expandBottom() {
    handleTransition(() => {
      switch (bottomState) {
        case STATES.LOW:
          setBottomState(STATES.HALF);
          break;
        case STATES.HALF:
          setBottomState(STATES.FULL);
          break;
        default:
          break;
      }
    });
  }

  function shrinkBottom() {
    handleTransition(() => {
      switch (bottomState) {
        case STATES.FULL:
          setBottomState(STATES.HALF);
          break;
        case STATES.HALF:
          setBottomState(STATES.LOW);
          break;
        default:
          break;
      }
    });
  }

  const heights = getHeights();
  const transitionClasses = isTransitioning
    ? "transition-all duration-300"
    : "";

  return (
    <div className="h-screen w-full flex flex-col relative">
      <div
        className={`${heights.top} relative flex flex-col ${transitionClasses}`}
      >
        {TopContent}
      </div>

      <div
        className={`${heights.bottom} relative flex flex-col ${transitionClasses}`}
      >
        <PanelControls
          onExpand={expandBottom}
          onShrink={shrinkBottom}
          isFullyExpanded={bottomState === STATES.FULL}
          isFullyCollapsed={bottomState === STATES.LOW}
        />
        {BottomContent}
      </div>
    </div>
  );
}
