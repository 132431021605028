import React, { useState } from "react";
import { DraftPicksList } from "./DraftResults";

export function ResultsTab() {
  const [activeTab, setActiveTab] = useState("picks");

  const tabs = [
    { id: "picks", label: "Picks" },
    { id: "teams", label: "Teams" },
    { id: "board", label: "Board" },
  ];

  return (
    <div className="h-[calc(100vh-9.5rem)] overflow-y-auto">
      <div className="px-2 pb-24 md:pb-4">
        {/*<Tabs tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab} />*/}

        <TabPanel activeTab={activeTab} tabId="picks">
          <DraftPicksList />
        </TabPanel>

        <TabPanel activeTab={activeTab} tabId="teams">
          Teams Content
        </TabPanel>

        <TabPanel activeTab={activeTab} tabId="board">
          Board Content
        </TabPanel>
      </div>
    </div>
  );
}

function Tabs({ tabs, activeTab, onTabChange }) {
  return (
    <div className="mx-auto mb-4 flex w-min items-center justify-center space-x-1 whitespace-nowrap rounded-full bg-sky-900 p-1 text-center text-md font-medium">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => onTabChange(tab.id)}
          className={`rounded-full px-4 py-1 ${
            activeTab === tab.id
              ? "bg-white/20 text-white"
              : "text-white/70 hover:bg-white/10 hover:text-white/90"
          }`}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
}

function TabPanel({ children, activeTab, tabId }) {
  if (activeTab !== tabId) return null;
  return <div className="">{children}</div>;
}
