import { useCallback, useEffect, useState } from "react";
import {
  fetchAndUpdateDraftData,
  initStaticData,
} from "../../lib/utils/draftStoreUtils";

export function useInitDraft({ boardId, staticData }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Initialize static data once
  useEffect(() => {
    initStaticData(staticData);
  }, [staticData]);

  const fetchDraftData = useCallback(async () => {
    return fetchAndUpdateDraftData(boardId, { setLoading, setError });
  }, [boardId]);

  // Fetch dynamic data
  useEffect(() => {
    fetchDraftData();
  }, [fetchDraftData]);

  return { loading, error, fetchDraftData };
}
