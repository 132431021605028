import React, { useEffect, useRef, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import useDraftStore from "../../lib/store/DraftStore";
import { QueuePlayerCard } from "./QueuePlayerCard";
import { DragHandle } from "./DragHandle";
import { DraftAPI } from "../../lib/utils/api";

function SortablePlayerCard({ player, index }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: player.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    player && (
      <div
        ref={setNodeRef}
        style={style}
        className="mb-2 bg-white dark:bg-gray-800 rounded-lg shadow select-none"
      >
        <div className="flex items-center">
          <div className="w-10 h-10 flex items-center justify-center text-sm font-semibold text-gray-500 dark:text-gray-400">
            {index + 1}
          </div>
          <QueuePlayerCard player={player} />
          <DragHandle
            id={player.id}
            index={index}
            {...attributes}
            {...listeners}
          />
        </div>
      </div>
    )
  );
}

export function QueueTab() {
  const {
    players,
    picks,
    queueIds,
    setQueueIds,
    autopicking,
    userTeamId,
    boardId,
    setAutopicking,
  } = useDraftStore();
  const [activeId, setActiveId] = useState(null);
  const activeIdRef = useRef(null);

  // Keep activeIdRef in sync with activeId
  useEffect(() => {
    activeIdRef.current = activeId;
  }, [activeId]);

  // Monitor queueIds changes and cancel drag if needed
  useEffect(() => {
    if (activeIdRef.current && !queueIds.includes(activeIdRef.current)) {
      // Player being dragged was removed from queue - cancel the drag
      document.dispatchEvent(new KeyboardEvent("keydown", { key: "Escape" }));
    }
  }, [queueIds]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const queuePlayers = players
    .filter((player) => queueIds.includes(player.id))
    .sort((a, b) => queueIds.indexOf(a.id) - queueIds.indexOf(b.id));

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over?.id && queueIds.includes(active.id)) {
      const oldIndex = queueIds.indexOf(active.id);
      const newIndex = queueIds.indexOf(over.id);

      const newQueueIds = [...queueIds];
      newQueueIds.splice(oldIndex, 1);
      newQueueIds.splice(newIndex, 0, active.id);

      setQueueIds(newQueueIds);
    }

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  // Check if autopicking is enabled for the current user
  const isAutopickEnabled =
    Array.isArray(autopicking) && autopicking.includes(userTeamId);

  // Handle the toggle change for autopicking
  async function handleAutopickToggle(e) {
    const isChecked = e.target.checked;

    // Store the original autopicking value for restoration if needed
    const originalAutopicking = [...(autopicking || [])];

    try {
      // Update local state immediately for responsive UI
      const updatedAutopicking = isChecked
        ? [...(originalAutopicking || []), userTeamId]
        : originalAutopicking.filter((id) => id !== userTeamId);

      setAutopicking(updatedAutopicking);

      // Call API to update the server state
      await DraftAPI.toggleAutopick(boardId, userTeamId, isChecked);
    } catch (error) {
      console.error("Failed to toggle autopick:", error);

      // Restore original state on error
      setAutopicking(originalAutopicking);
    }
  }

  if (queuePlayers.length === 0) {
    return (
      <div className="flex items-center justify-center p-8 text-gray-500 dark:text-gray-400">
        No players in queue
      </div>
    );
  }

  const overlayPlayer = queuePlayers.find((p) => p.id === activeId);

  return (
    <div className="h-[calc(100vh-9.5rem)] lg:h-full overflow-y-auto">
      <div className="flex items-center justify-end p-4">
        <label className="inline-flex items-center cursor-pointer">
          <span className="me-4 text-sm font-light text-gray-900 dark:text-gray-300">
            Autopick top player when it's my turn
          </span>
          <input
            type="checkbox"
            className="sr-only peer"
            checked={isAutopickEnabled}
            onChange={handleAutopickToggle}
          />
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 dark:peer-checked:bg-blue-600"></div>
        </label>
      </div>
      <div className="px-2 pb-24 md:pb-4">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            items={queuePlayers.map((p) => p.id)}
            strategy={verticalListSortingStrategy}
          >
            {queuePlayers.map((player, index) => (
              <SortablePlayerCard
                key={player.id}
                player={player}
                index={index}
              />
            ))}
          </SortableContext>
          <DragOverlay>
            {activeId && overlayPlayer ? (
              <div className="mb-2 bg-white dark:bg-gray-800 rounded-lg shadow">
                <div className="flex items-center">
                  <div className="w-10 h-10 flex items-center justify-center text-sm font-semibold text-gray-500 dark:text-gray-400">
                    {queuePlayers.findIndex((p) => p.id === activeId) + 1}
                  </div>
                  <QueuePlayerCard player={overlayPlayer} />
                  <DragHandle
                    id={activeId}
                    index={queuePlayers.findIndex((p) => p.id === activeId)}
                  />
                </div>
              </div>
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>
    </div>
  );
}
