import PickCell from "./PickCell";
import KeeperCell from "./KeeperCell";
import React from "react";
import useDraftStore from "../../lib/store/DraftStore";
import { IconAutopicking } from "../IconAutopicking";

export function TeamColumn({
  boardTeamId,
  team,
  isUserTeam,
  picks,
  players,
  isCommish,
  leagueSeasonId,
  keptPlayerIds,
}) {
  const autopicking = useDraftStore((state) => state.autopicking);
  const isAutopicking =
    Array.isArray(autopicking) && autopicking.includes(boardTeamId);

  const keptPlayers = players.filter((player) =>
    keptPlayerIds.includes(player.id),
  );
  const teamPicks = picks.filter((pick) => pick.originalTeamId === boardTeamId);

  return (
    <div key={boardTeamId} className="w-full min-w-32">
      <div className="sticky top-0 z-20 content-end bg-gray-50 pb-0.5 pt-1 text-center dark:bg-gray-900 dark:text-gray-400">
        <div
          className={`rounded pb-1 pt-2 ${
            isUserTeam
              ? "dark dark:bg-gray-700 dark:text-white"
              : "bg-gray-300 dark:bg-gray-800"
          } text-center `}
        >
          {team.imgName ? (
            <div className="w-6 h-6 mx-auto rounded-full shrink-0 overflow-hidden relative">
              <img
                src={team.imgPath}
                alt={team.name}
                className="w-full h-full object-cover"
              />
              {isAutopicking && (
                <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
                  <IconAutopicking className="size-3 text-white/90" />
                </div>
              )}
            </div>
          ) : (
            <div
              className="w-6 h-6 mx-auto shrink-0 text-sm flex items-center justify-center text-white text-opacity-90 rounded-full overflow-hidden relative"
              style={{ backgroundColor: team.color }}
            >
              {isAutopicking ? (
                <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
                  <IconAutopicking className="size-3 text-white/90" />
                </div>
              ) : (
                team.name.charAt(0).toUpperCase()
              )}
            </div>
          )}

          <div className="mt-0.5">
            <span className="block truncate px-2 text-sm">{team.name}</span>
          </div>
        </div>
      </div>

      <div>
        {teamPicks.map((pick) => (
          <PickCell
            key={pick.id}
            pick={pick}
            player={players.find((player) => pick.playerId === player.id)}
            isCommish={isCommish}
            leagueSeasonId={leagueSeasonId}
          />
        ))}

        {keptPlayers.length > 0 && (
          <>
            <div className="mt-0.5 sticky top-9 z-10 flex min-h-14 flex-col items-center justify-end pb-1.5 text-center text-xxs font-medium text-gray-500 dark:text-gray-400">
              <span className="text-xs text-white uppercase rounded-full bg-gray-500/70 px-2.5 py-0.5 font-medium dark:bg-gray-600/70 dark:text-gray-200">
                Keepers
              </span>
            </div>

            {keptPlayers.map((player) => (
              <KeeperCell key={player.id} player={player} />
            ))}
          </>
        )}
      </div>
    </div>
  );
}
