import React from "react";
import { ResizablePanels } from "./ResizablePanels";
import { TopPanel } from "./TopPanel";
import { BottomPanel } from "./BottomPanel";

// Main component that puts it all together
export function DraftRoomDesktop() {
  return (
    <ResizablePanels
      TopContent={<TopPanel />}
      BottomContent={<BottomPanel />}
    />
  );
}
