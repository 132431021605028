// draftStoreUtils.js
import useDraftStore from "../../lib/store/DraftStore";
import { DraftAPI } from "./api";

export function initStaticData({ teams, players, league, userTeamId }) {
  const draftStore = useDraftStore.getState();

  if (league.scoringType === "Categories") {
    draftStore.setSortConfig({ key: "ranks.average", direction: "asc" });
  } else {
    draftStore.setSortConfig({ key: "points.proj", direction: "desc" });
  }

  draftStore.setTeamsById(teams);
  draftStore.setPlayers(players);
  draftStore.setLeagueData(league);
  draftStore.setSelectedTeamId(window.innerWidth >= 1024 ? userTeamId : null);
}

export const updateDraftStore = (draftData) => {
  const {
    boardId,
    leagueSeasonId,
    userTeamId,
    boardData,
    picks,
    queueIds,
    keptPlayerIdsByTeamId,
  } = draftData;

  const draftStore = useDraftStore.getState();

  const draftedPlayerIds = [
    ...Object.values(keptPlayerIdsByTeamId).flat(),
    ...picks.map((pick) => pick.playerId),
  ];
  const nonDraftedQueueIds = queueIds.filter(
    (id) => !draftedPlayerIds.includes(id),
  );

  draftStore.setBoardId(boardId);
  draftStore.setBoardData(boardData);
  draftStore.setPickExpiresAt(boardData.currentPickExpiresAt);
  draftStore.setLeagueSeasonId(leagueSeasonId);
  draftStore.setUserTeamId(userTeamId);
  draftStore.setPicks(picks);
  draftStore.setCurrentPickId(boardData.currentPickId);
  draftStore.setKeptPlayerIdsByTeamId(keptPlayerIdsByTeamId);
  draftStore.setDraftedPlayerIds(draftedPlayerIds);
  draftStore.setQueueIds(nonDraftedQueueIds, false);
  draftStore.setDraftStatus(boardData.status);
  draftStore.setAutopicking(boardData.autopicking);

  draftStore.setLastFetchTime(Date.now());
};

export const fetchAndUpdateDraftData = async (
  boardId,
  { setLoading, setError } = {},
) => {
  try {
    if (setLoading) setLoading(true);
    if (setError) setError(null);

    const draftData = await DraftAPI.fetchDraftData(boardId);
    updateDraftStore(draftData);

    return draftData;
  } catch (err) {
    if (setError) setError(err.message);
    throw err;
  } finally {
    if (setLoading) setLoading(false);
  }
};
