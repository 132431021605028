import React, { useEffect, useState } from "react";

import { Combobox } from "@headlessui/react";
import { IconMagnifyingGlass } from "../../../global/components/icons/IconMagnifyingGlass";

export default function PlayerSearch({
  seasonPlayers,
  showIds = [],
  hideIds = [],
  hiddenFieldName,
}) {
  const hideSelected = false;
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const hiddenInput = document.querySelector(
      `input[name="${hiddenFieldName}"]`,
    );

    if (hiddenInput && hiddenInput.value) {
      const playerId = parseInt(hiddenInput.value);
      const player = seasonPlayers.find((player) => player.id === playerId);
      if (player) {
        setSelectedPlayer(player);
        setQuery(player.firstName + " " + player.lastName);
      }
    }
  }, [seasonPlayers, hiddenFieldName]);

  const showPlayers =
    showIds.length > 0
      ? seasonPlayers.filter((player) => showIds.includes(player.id))
      : seasonPlayers;

  const filteredHidden = hideSelected
    ? showPlayers.filter((player) => {
        return player.id === selectedPlayer?.id || !hideIds.includes(player.id);
      })
    : [];

  const filteredPeople =
    query.length < 1
      ? filteredHidden.slice(0, 10)
      : showPlayers
          .filter((player) => {
            return (
              player.name.toLowerCase().includes(query.toLowerCase()) &&
              !hideIds.includes(player.id)
            );
          })
          .slice(0, 20);

  function selectPlayer(player) {
    if (player) {
      setSelectedPlayer(player);
      setQuery(player.firstName + " " + player.lastName);

      // Update the hidden input field in the Symfony form
      const hiddenInput = document.querySelector(
        `input[name="${hiddenFieldName}"]`,
      );

      if (hiddenInput) {
        hiddenInput.value = player.id;
      }
    }
  }

  function handleClear() {
    setSelectedPlayer(null);
    setQuery("");

    // Clear the hidden input field
    const hiddenInput = document.querySelector(
      `input[name="${hiddenFieldName}"]`,
    );
    if (hiddenInput) {
      hiddenInput.value = "";
    }
  }

  return (
    <Combobox value={selectedPlayer} onChange={selectPlayer}>
      <div className="relative">
        {IconMagnifyingGlass}
        <Combobox.Input
          onChange={(event) => setQuery(event.target.value)}
          value={query}
          className="h-12 w-full border-0 bg-transparent pl-11 pr-9 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-0 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-200 sm:text-md"
          placeholder={`Search...`}
          autoComplete="off"
          autoCorrect="off"
        />
        {query && (
          <button
            className="absolute bottom-0 right-2 top-0 text-gray-600 dark:text-gray-400"
            onClick={handleClear}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        )}
      </div>

      {filteredPeople.length > 0 && (
        <Combobox.Options
          className={`z-20 scroll-py-2 overflow-y-auto border-0 border-t border-gray-200 dark:border-gray-600 py-2 text-md text-gray-800 dark:bg-gray-800`}
        >
          {filteredPeople.map((player) => (
            <Combobox.Option
              disabled={false}
              className={false ? "opacity-50" : ""}
              key={player.id}
              value={player}
            >
              {({ focus, selected }) => (
                <div
                  className={`flex cursor-default select-none items-center justify-between px-4 py-2 font-medium leading-snug ${
                    focus ? "bg-gray-100 dark:bg-gray-700" : ""
                  }`}
                  id="headlessui-combobox-option-5"
                  role="option"
                  tabIndex="-1"
                  aria-selected="false"
                  data-headlessui-state=""
                >
                  <div className="my-1 flex items-center justify-center dark:text-white">
                    <div className="mr-3 h-7 w-7">
                      <img
                        className="h-7 w-7 rounded-full"
                        src={`/build/images/sport/teams/logos/512/${player.teamId}.png`}
                      />
                    </div>
                    <div className="flex-grow text-md">
                      <span className="inline-flex whitespace-nowrap items-center justify-center">
                        {player.firstName} {player.lastName}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      )}
    </Combobox>
  );
}
