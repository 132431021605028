import React from "react";

export function DragHandle({ id, index, ...props }) {
  return (
    <button
      className="w-12 px-2 h-10 flex flex-col items-center justify-center gap-1 text-gray-400 cursor-grab active:cursor-grabbing touch-none"
      {...props}
    >
      <div className="flex flex-col gap-1">
        <div className="w-4 h-0.5 bg-current"></div>
        <div className="w-4 h-0.5 bg-current"></div>
        <div className="w-4 h-0.5 bg-current"></div>
      </div>
      <span className="sr-only">Drag item {index + 1}</span>
    </button>
  );
}