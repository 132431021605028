import React from "react";

export function ChatTab() {
  return (
    <div className="h-[calc(100vh-9.5rem)] lg:h-full overflow-y-auto">
      <div className="px-2 pt-4 pb-24 md:pb-4">
        <div className="pt-12 text-md text-center dark:text-gray-400">
          Draft Chat coming soon
        </div>
      </div>
    </div>
  );
}
