import { getTeamById } from "../../global/lib/utils/teams";
import React, { useEffect } from "react";
import useDraftStore from "../lib/store/DraftStore";
import { TeamSelector } from "./TeamSelector";
import { getPlayerPositionLabel } from "../../global/lib/utils/playerPositions";
import TeamLineup from "./TeamLineup";

export function PickRow({
  pick,
  player,
  team,
  isUserTeam,
  leagueSeasonId,
  displayNumber,
}) {
  const playerTeam = player ? getTeamById(player.teamId) : null;

  return (
    <div
      className={`flex ${pick.roundPick === 1 ? "border-y" : "border-b"} dark:border-gray-700 items-center text-md gap-2 p-2 ${isUserTeam ? "dark:bg-gray-800" : "bg-white dark:bg-gray-900"} ${pick.surplus ? "opacity-50" : ""}`}
    >
      <span
        className={`${isUserTeam ? "text-gray-900 dark:text-gray-200" : "text-gray-700 dark:text-gray-400"} ${pick.surplus ? "line-through" : ""} text-center text-md font-medium w-10`}
      >
        {displayNumber}
      </span>
      <div className="w-8 h-8 rounded-full overflow-hidden bg-gray-100">
        {team.imgName ? (
          <img
            src={team.imgPath}
            alt={team.name}
            className="w-full h-full object-cover"
          />
        ) : (
          <div
            className="w-full h-full flex items-center justify-center text-white"
            style={{ backgroundColor: team.color }}
          >
            {team.name.charAt(0)}
          </div>
        )}
      </div>
      <div className="min-w-0 flex-1 flex flex-col items-start justify-center space-y-0.5">
        <div
          className={`${isUserTeam ? "text-gray-900 dark:text-gray-200" : "text-gray-900 dark:text-gray-200"} text-md tracking-tight font-medium leading-tight truncate`}
        >
          {player ? (
            <div className="flex items-center gap-2">
              <a
                className="dark:text-gray-100 md:hover:underline"
                data-turbo-frame="modal"
                href={`/afl/${leagueSeasonId}/players/profile/${player.id}`}
              >
                {player.abbrName}
              </a>
              <span
                className={`text-xs ${isUserTeam ? "text-gray-700 dark:text-gray-100" : "text-gray-600 dark:text-gray-200"}`}
              >
                {getPlayerPositionLabel(player.positions)}
              </span>
            </div>
          ) : (
            team.name
          )}
        </div>
        {player ? (
          <div
            className={`${isUserTeam ? "text-gray-900 dark:text-gray-400" : "text-gray-700 dark:text-gray-400"} text-xs font-medium leading-none`}
          >
            {team.name}
          </div>
        ) : team.coaches ? (
          <div
            className={`${isUserTeam ? "text-gray-900 dark:text-gray-400" : "text-gray-700 dark:text-gray-400"} text-xs leading-none`}
          >
            {team.coaches}
          </div>
        ) : null}
      </div>
      {player && (
        <div className="overflow relative -mb-2.5 mr-2.5 flex h-10 w-8 items-start justify-center overflow-clip">
          <img
            className="w-full"
            src={`/build/images/sport/teams/guernseys/${playerTeam?.abbr}.png`}
            alt={`${playerTeam?.name} guernsey`}
          />
        </div>
      )}
      {pick.surplus && (
        <span className="text-gray-400 w-10 text-center text-sm">
          Team Full
        </span>
      )}
      <span className="text-gray-400 w-3 text-center text-sm">
        {pick.pickMethod === 1 ? "C" : pick.pickMethod === 2 ? "A" : ""}
      </span>
    </div>
  );
}

export function DraftPicksList() {
  const [
    picks,
    players,
    teamsById,
    userTeamId,
    currentPickId,
    leagueSeasonId,
    selectedTeamId,
    keptPlayerIdsByTeamId,
  ] = useDraftStore((state) => [
    state.picks,
    state.players,
    state.teamsById,
    state.userTeamId,
    state.currentPickId,
    state.leagueSeasonId,
    state.selectedTeamId,
    state.keptPlayerIdsByTeamId,
  ]);

  const currentPickRef = React.useRef(null);
  const lastPickId = React.useRef(currentPickId);
  const containerRef = React.useRef(null);

  useEffect(() => {
    const currentElement = currentPickRef.current;
    const container = containerRef.current?.closest(".overflow-y-auto");

    if (container && currentElement && window.innerWidth < 1024) {
      const { top: elementTop } = currentElement.getBoundingClientRect();
      const { height: containerHeight } = container.getBoundingClientRect();
      const offset = containerHeight * 0.9;

      container.scrollTo({
        top: elementTop - offset,
        behavior: "instant",
      });
    }
  }, [selectedTeamId]);

  useEffect(() => {
    if (currentPickId !== lastPickId.current) {
      lastPickId.current = currentPickId;
      const container = containerRef.current?.closest(".overflow-y-auto");
      const currentElement = currentPickRef.current;

      if (container && currentElement) {
        const { top, bottom } = currentElement.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        const isVisible =
          top >= containerRect.top && bottom <= containerRect.bottom;

        if (isVisible) {
          const pickHeight = bottom - top;
          container.scrollBy({ top: pickHeight, behavior: "smooth" });
        }
      }
    }
  }, [currentPickId]);

  const filteredPicks = selectedTeamId
    ? picks.filter((pick) => pick.teamId === parseInt(selectedTeamId))
    : picks;
  const picksRemaining = filteredPicks.filter(
    (pick) => pick.playerId === null && !pick.surplus,
  ).length;

  const getKeepersForTeam = (teamId) => {
    const keeperIds = keptPlayerIdsByTeamId[teamId] || [];
    return keeperIds
      .map((id) => players.find((player) => player.id === id))
      .filter(Boolean)
      .map((player, index) => ({
        player,
        id: `keeper-${teamId}-${index}`,
        teamId,
        roundPick: 1,
        surplus: false,
        pickMethod: 0,
      }));
  };

  const getKeepersList = () => {
    if (selectedTeamId) {
      return getKeepersForTeam(parseInt(selectedTeamId));
    }

    const allKeepers = Object.keys(keptPlayerIdsByTeamId).flatMap((teamId) =>
      getKeepersForTeam(parseInt(teamId)),
    );

    return allKeepers;
  };

  const keepers = getKeepersList();

  return (
    <div className="-mx-2" ref={containerRef}>
      <div className="sticky top-0 z-20">
        <TeamSelector teamsById={teamsById} picksRemaining={picksRemaining} />
      </div>
      {selectedTeamId ? (
        <TeamLineup teamId={selectedTeamId} />
      ) : (
        <>
          {filteredPicks.map((pick, index) => {
            const showRound =
              !selectedTeamId &&
              (index === 0 || filteredPicks[index - 1]?.round !== pick.round);
            const team = teamsById[pick.teamId];
            const player = players.find(
              (player) => pick.playerId === player.id,
            );
            const isCurrentPick = pick.id === currentPickId;

            return (
              <React.Fragment key={pick.overallPick}>
                {showRound && (
                  <div className="sticky top-14 z-10 py-1 inline-flex w-full items-center justify-center">
                    <span className="text-sm text-white rounded-full bg-emerald-500 px-3.5 py-1 font-medium dark:bg-gray-700 dark:text-gray-200">
                      ROUND {pick.round}
                    </span>
                  </div>
                )}
                <div ref={isCurrentPick ? currentPickRef : null}>
                  <PickRow
                    pick={pick}
                    player={player}
                    team={team}
                    isUserTeam={userTeamId === team.id}
                    leagueSeasonId={leagueSeasonId}
                    displayNumber={pick.overallPick}
                  />
                </div>
              </React.Fragment>
            );
          })}
          {keepers.length > 0 && (
            <>
              <div className="sticky top-14 z-10 py-1 inline-flex w-full items-center justify-center">
                <span className="text-sm text-white rounded-full bg-gray-500 px-4.5 py-1 font-medium dark:bg-gray-700 dark:text-gray-200">
                  KEEPERS
                </span>
              </div>
              {keepers.map((keeperPick) => {
                const team = teamsById[keeperPick.teamId];
                return (
                  <PickRow
                    key={keeperPick.id}
                    pick={keeperPick}
                    player={keeperPick.player}
                    team={team}
                    isUserTeam={userTeamId === team.id}
                    leagueSeasonId={leagueSeasonId}
                    displayNumber="K"
                  />
                );
              })}
            </>
          )}
        </>
      )}
    </div>
  );
}
