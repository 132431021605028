import { PlayerCard } from "./PlayerCard";
import React, { useEffect, useRef, useState } from "react";

export const PlayerList = ({ players }) => {
  return window.innerWidth < 768 ? (
    <InfinitePlayerList players={players} />
  ) : (
    <aside className="pointer-events-none absolute left-0 w-full min-w-80 md:pointer-events-auto md:static">
      <div className="sticky -top-px z-10 hidden h-[58px] content-end rounded-tl-md border-b border-gray-200 bg-white py-1.5 pl-3 font-medium md:block dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300">
        Players
      </div>
      {players.map((player) => (
        <div
          key={player.id}
          className="mb-[19px] px-4 flex whitespace-nowrap border-b-0 border-gray-200 md:mb-0 md:flex-row-reverse md:justify-end md:border-b dark:border-gray-700"
        >
          <PlayerCard player={player} />
        </div>
      ))}
    </aside>
  );
};
// Load players in chunks for performance optimization
// Load first 10, then 50 more in chunks
const InfinitePlayerList = ({ players }) => {
  const [visiblePlayers, setVisiblePlayers] = useState(30);
  const loaderRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && visiblePlayers < players.length) {
          setVisiblePlayers((prev) => Math.min(prev + 100, players.length));
        }
      },
      { threshold: 0.1 },
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => observer.disconnect();
  }, [visiblePlayers, players.length]);

  return (
    <aside className="pointer-events-none absolute left-0 w-full min-w-80 md:pointer-events-auto md:static">
      <div className="sticky -top-px z-10 hidden h-[58px] content-end rounded-tl-md border-b border-gray-200 bg-white py-1.5 pl-3 font-medium md:block dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300">
        Players
      </div>
      {players.slice(0, visiblePlayers).map((player) => (
        <div
          key={player.id}
          className="mb-[19px] px-4 flex whitespace-nowrap border-b-0 border-gray-200 md:mb-0 md:flex-row-reverse md:justify-end md:border-b dark:border-gray-700"
        >
          <PlayerCard player={player} />
        </div>
      ))}
      <div ref={loaderRef} className="h-0.5" />
    </aside>
  );
};
