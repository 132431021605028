export function getPlayerPositionLabel(playerPositions) {
  if (!playerPositions || playerPositions.length === 0) {
    return null;
  }

  if (playerPositions.length >= 4) {
    return "ALL";
  }

  if (playerPositions.length > 2) {
    // Only use first character of each position
    playerPositions = playerPositions.map((position) => position.charAt(0));
  }

  return playerPositions.join("/");
}
