import React from "react";
import { UpcomingStrip } from "../UpcomingStrip";
import { OnTheClock } from "../OnTheClock";
import { TabBar, TabContent } from "../Tabs";
import { StatusBar } from "../StatusBar";

export function DraftRoomMobile() {
  return (
    <div className="w-full h-full bg-gray-50 dark:bg-gray-900 flex flex-col">
      <div className="flex flex-col sticky top-0 z-20">
        <StatusBar />
        <UpcomingStrip />
        <OnTheClock />
        <TabBar />
      </div>
      <TabContent />
    </div>
  );
}
