import React from "react";
import useDraftStore from "../lib/store/DraftStore";
import { DraftAPI } from "../lib/utils/api";
import { IconAutopicking } from "./IconAutopicking";

export function StatusBar() {
  const [autopicking, userTeamId, boardId, setAutopicking] = useDraftStore(
    (state) => [
      state.autopicking,
      state.userTeamId,
      state.boardId,
      state.setAutopicking,
    ],
  );

  const isVisible =
    Array.isArray(autopicking) && autopicking.includes(userTeamId);

  if (!isVisible) return null;

  const handleTurnOffAutopick = async (e) => {
    // Store the original autopicking value for restoration if needed
    const originalAutopicking = [...autopicking];

    try {
      if (Array.isArray(autopicking)) {
        const updatedAutopicking = autopicking.filter(
          (id) => id !== userTeamId,
        );
        setAutopicking(updatedAutopicking);
      }

      await DraftAPI.toggleAutopick(boardId, userTeamId, false);
    } catch (error) {
      console.error("Failed to turn off autopick:", error);

      setAutopicking(originalAutopicking);
    }
  };

  return (
    <div
      className={`pointer-events-none absolute flex items-center justify-center top-0 w-full z-50`}
    >
      <div className="py-1.5 lg:py-3 flex items-center justify-center text-sm  md:text-base px-4 lg:px-6 rounded-b-lg lg:rounded-b-2xl bg-yellow-200 backdrop-blur-sm text-black">
        <span className="inline-flex items-center justify-center">
          <IconAutopicking className={`size-3 lg:size-4 mr-1.5 text-black`} />
          Autopick enabled
        </span>{" "}
        <button
          className="underline ml-3 md:ml-8 font-semibold pointer-events-auto"
          onClick={handleTurnOffAutopick}
        >
          TURN OFF
        </button>
      </div>
    </div>
  );
}
