import React from "react";

export function HeaderContainer({
  columnGroups,
  currentSort,
  headerRef,
  onSort,
}) {
  return (
    <div
      id="headerContainer"
      className="sticky top-[9.5rem] z-20 -mx-2 overflow-clip overscroll-x-none border-y border-gray-200 bg-white md:mx-0 md:hidden dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
      ref={headerRef}
    >
      <table id="tableHeader" className="w-full text-xs md:table-auto">
        <thead>
          <tr>
            {columnGroups.map((group) =>
              group.columns.map((col) => (
                <th
                  key={col.sortParam}
                  className={`whitespace-nowrap py-2 text-center font-medium md:min-w-0 ${
                    currentSort === col.sortParam
                      ? "md:bg-gray-50 md:dark:bg-gray-700"
                      : ""
                  }`}
                >
                  <button
                    onClick={() => onSort(col.sortParam)}
                    className={`block h-full w-full sm:hover:text-orange-500 sm:dark:hover:text-orange-400 ${
                      currentSort === col.sortParam
                        ? "text-orange-500 dark:text-orange-400"
                        : ""
                    }`}
                  >
                    {col.mobileTitle ?? col.title}
                  </button>
                </th>
              )),
            )}
          </tr>
        </thead>
      </table>
    </div>
  );
}

export function TableHeader({ columnGroups, currentSort, onSort }) {
  return (
    <thead className="sticky top-0 z-10 hidden bg-white md:table-header-group dark:bg-gray-800 dark:text-gray-300">
      <tr className="h-7">
        {columnGroups.map((group, groupIndex) => (
          <th
            key={groupIndex}
            colSpan={group.columns.length}
            className={`whitespace-nowrap border-b border-gray-200 py-1.5 text-center font-medium md:border-l md:border-l-gray-100 dark:border-gray-700`}
          >
            {group.title}
          </th>
        ))}
      </tr>
      <tr className="h-7">
        {columnGroups.map((group, groupIndex) =>
          group.columns.map((column, colIndex) => (
            <th
              key={`${groupIndex}-${colIndex}`}
              className={`border-b ${
                colIndex === 0 ? "md:border-l md:border-l-gray-100" : ""
              } border-gray-200 p-1.5 text-center whitespace-nowrap font-medium dark:border-gray-700 ${
                currentSort === column.sortParam
                  ? "bg-gray-50 dark:bg-gray-700"
                  : ""
              }`}
              title={column.desc}
            >
              <button
                onClick={() => onSort(column.sortParam)}
                className={`hover:underline ${
                  currentSort === column.sortParam
                    ? "font-semibold text-orange-500 dark:text-orange-400"
                    : ""
                }`}
              >
                {column.title}
              </button>
            </th>
          )),
        )}
      </tr>
    </thead>
  );
}
