import React from "react";
import { PlayersTab } from "../PlayersTab";
import { BottomTabContent, BottomTabs } from "../BottomTabs";

export function BottomPanel() {
  return (
    <div className="flex h-full">
      <div
        id="players-container"
        className="flex-1 flex flex-col w-1/2 xl:w-full"
      >
        <PlayersTab />
      </div>
      <div
        id="right-container"
        className="min-w-96 flex flex-col shadow-xl w-1/4"
      >
        <div id="right-tabs" className="flex flex-col sticky top-0 z-20">
          <BottomTabs />
        </div>
        <div id="right-content" className="flex-1 overflow-auto">
          <BottomTabContent />
        </div>
      </div>
    </div>
  );
}
