import React, { useEffect, useState } from "react";

export function AlertNotification({
  message,
  type = "success",
  duration = 5000,
  onClose,
}) {
  const [isVisible, setIsVisible] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const [isEntering, setIsEntering] = useState(true);

  useEffect(() => {
    // Show notification when mounted
    setIsVisible(true);

    // Start enter animation after a frame to ensure CSS transition works
    const enterTimer = setTimeout(() => {
      setIsEntering(false);
    }, 10);

    // If duration is not "persist"
    if (duration !== 9999999999) {
      const hideTimer = setTimeout(() => {
        handleClose();
      }, duration);

      return () => {
        clearTimeout(hideTimer);
        clearTimeout(enterTimer);
      };
    }

    return () => clearTimeout(enterTimer);
  }, [duration, message]); // Add message as dependency to reset animation when message changes

  const handleClose = () => {
    setIsLeaving(true);
    // Wait for animation to complete before removing
    setTimeout(() => {
      setIsVisible(false);
      if (onClose) onClose();
    }, 500); // Match the transition duration
  };

  if (!isVisible) return null;

  const bgColor =
    type === "success"
      ? "bg-green-500 dark:bg-green-900 dark:border-green-700"
      : "bg-red-500 dark:bg-red-900 dark:border-red-800";

  const hoverBgColor =
    type === "success"
      ? "hover:bg-green-400 dark:hover:bg-green-800"
      : "hover:bg-red-400 dark:hover:bg-red-800";

  const buttonBgColor =
    type === "success"
      ? "bg-green-500 dark:bg-green-900"
      : "bg-red-500 dark:bg-red-900";

  const icon =
    type === "success" ? (
      <svg
        className="size-6 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    ) : (
      <svg
        className="size-6 text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
    );

  const transitionClasses = isEntering
    ? "opacity-0 -translate-y-6 sm:translate-y-0 sm:translate-x-6" // Enter from
    : isLeaving
      ? "opacity-0 -translate-y-6 sm:translate-y-0 sm:translate-x-6" // Leave to
      : "opacity-100 translate-y-0 sm:translate-y-0 sm:translate-x-0"; // Enter to / Leave from

  return (
    <div
      className={`pointer-events-none fixed inset-0 z-50 flex transform items-start justify-center px-4 py-6 transition duration-500 ease-out sm:items-start sm:justify-end sm:p-6 sm:pt-6 ${transitionClasses}`}
    >
      <div
        className={`shadow-xs pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg ${bgColor} p-4 shadow-lg dark:border dark:shadow-none`}
      >
        <div className="flex items-start">
          {icon}

          <div className="ml-3 w-0 flex-1 pt-0.5">
            <p
              className="mb-0 text-md font-medium text-white dark:text-gray-200"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </div>

          <div className="ml-4 flex flex-shrink-0">
            <button
              onClick={handleClose}
              className={`inline-flex rounded-md ${buttonBgColor} p-0.5 text-white/70 ${hoverBgColor} hover:text-white focus:outline-none dark:text-gray-200`}
            >
              <span className="sr-only">Close</span>
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
