import { createWithEqualityFn } from "zustand/traditional";
import { fetchAndUpdateDraftData } from "../utils/draftStoreUtils";

const useDraftStore = createWithEqualityFn(
  (set, get) => ({
    lastFetchTime: Date.now(),
    setLastFetchTime: (time) => set({ lastFetchTime: time }),

    players: [],
    setPlayers: (players) => {
      set({ players: players });
    },

    sortConfig: null,
    setSortConfig: (config) => {
      set({ sortConfig: config });
    },

    picks: [],
    setPicks: (picks) => {
      set({ picks: picks });
    },
    updatePick: (pickId, playerId, isAutopick) => {
      // get().setOnTheClock(false);
      set((state) => ({
        picks: state.picks.map((pick) => {
          if (pick.id === parseInt(pickId)) {
            return {
              ...pick,
              playerId: parseInt(playerId),
              pickMethod: parseInt(isAutopick),
            };
          }
          return pick;
        }),
      }));
      get().addDraftedPlayerId(playerId);
      get().setCurrentPick(get().picks.find((pick) => pick.playerId === null));
    },

    queueIds: [],
    setQueueIds: async (queueIds, sync = true) => {
      const originalQueueIds = get().queueIds;
      set({ queueIds });

      if (sync) {
        try {
          const response = await fetch("/api/v1/draft/update-queue", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              queueIds: queueIds,
              teamId: get().userTeamId,
              boardId: get().boardId,
            }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}`);
          }
        } catch (error) {
          console.error("Failed to update queue:", error);
          set({ queueIds: originalQueueIds });
        }
      }
    },

    autopicking: [],
    setAutopicking: (autopicking) => {
      set({ autopicking: autopicking });
    },

    teamsById: [],
    setTeamsById: (teamsById) => {
      set({ teamsById: teamsById });
    },

    keptPlayerIdsByTeamId: [],
    setKeptPlayerIdsByTeamId: (keptPlayerIdsByTeamId) => {
      set({ keptPlayerIdsByTeamId: keptPlayerIdsByTeamId });
    },

    draftedPlayerIds: [],
    setDraftedPlayerIds: (draftedPlayerIds) => {
      set({ draftedPlayerIds: draftedPlayerIds });
    },
    addDraftedPlayerId: (playerId) => {
      set((state) => ({
        draftedPlayerIds: [...state.draftedPlayerIds, parseInt(playerId)],
      }));

      // Filter out any selected players
      const filteredIds = get().queueIds.filter(
        (id) => !get().draftedPlayerIds.includes(id),
      );

      set({ queueIds: filteredIds });
    },

    userTeamId: null,
    setUserTeamId: (userTeamId) => {
      set({ userTeamId: userTeamId });
    },

    currentPickId: null,
    setCurrentPickId: (currentPickId) => {
      set({ currentPickId: currentPickId });

      const currentPick = get().picks.find((pick) => pick.id === currentPickId);

      // Check if any earlier missing picks that weren't expected to be skipped
      const hasMissingPicks = get().picks.some(
        (pick) =>
          pick.playerId === null &&
          pick.overallPick < currentPick?.overallPick &&
          !pick.surplus,
      );
      if (hasMissingPicks) {
        console.log("missing picks detected, re-sync");
        fetchAndUpdateDraftData(get().boardId);
      }

      get().setOnTheClock(
        currentPick?.teamId === get().userTeamId && get().userTeamId !== null,
      );
    },

    pickExpiresAt: null,
    setPickExpiresAt: (pickExpiresAt) => {
      set({ pickExpiresAt: pickExpiresAt ? new Date(pickExpiresAt) : null });
    },

    onTheClock: false,
    setOnTheClock: (onTheClock) => {
      set({ onTheClock: onTheClock });
    },

    boardId: null,
    setBoardId: (boardId) => {
      set({ boardId: boardId });
    },

    boardData: null,
    setBoardData: (boardData) => {
      set({ boardData: boardData });
    },

    leagueData: null,
    setLeagueData: (leagueData) => {
      set({ leagueData: leagueData });
    },

    draftStatus: "Loading",
    setDraftStatus: (draftStatus) => {
      set({ draftStatus: draftStatus });
      console.log("Draft status changed to " + draftStatus);
    },

    leagueSeasonId: null,
    setLeagueSeasonId: (leagueSeasonId) => {
      set({ leagueSeasonId: leagueSeasonId });
    },

    selectedPlayerId: null,
    setSelectedPlayerId: (selectedPlayerId) => {
      set({ selectedPlayerId: selectedPlayerId });
    },

    selectedTab: "players",
    setSelectedTab: (selectedTab) => {
      set({ selectedTab: selectedTab });
    },

    selectedBottomTab: "queue",
    setSelectedBottomTab: (selectedTab) => {
      set({ selectedBottomTab: selectedTab });
    },

    searchQuery: "",
    setSearchQuery: (searchQuery) => {
      set({ searchQuery: searchQuery });
    },

    selectedPosition: "All",
    setSelectedPosition: (position) => set({ selectedPosition: position }),

    showDrafted: false,
    setShowDrafted: (value) => set({ showDrafted: value }),

    rookiesOnly: false,
    setRookiesOnly: (value) => set({ rookiesOnly: value }),

    showQueueOnly: false,
    setShowQueueOnly: (value) => set({ showQueueOnly: value }),

    hideLongTermInjuries: false,
    setHideLongTermInjuries: (value) => set({ hideLongTermInjuries: value }),

    selectedTeamId: null,
    setSelectedTeamId: (teamId) => set({ selectedTeamId: teamId }),
  }),
  Object.is,
);

export default useDraftStore;
