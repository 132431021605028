import {
  formationPositions,
  getFormationPosById,
} from "../../../global/lib/utils/formationPositions";

function initializeFormationBuckets(formation) {
  const formationBuckets = {
    limits: {},
    counts: {},
    starters: {},
    emergencies: {},
    positions: {},
    bench: [],
  };

  // Combine starters and emgs data to get total limits
  const allPositions = [
    ...new Set([
      ...formation.starters.map((p) => p.abbr),
      ...formation.emgs.map((p) => p.abbr),
    ]),
  ];

  allPositions.forEach((abbr) => {
    const starterLimit =
      formation.starters.find((p) => p.abbr === abbr)?.limit || 0;
    const emgLimit = formation.emgs.find((p) => p.abbr === abbr)?.limit || 0;

    formationBuckets.positions[abbr] = getFormationPosById(abbr);
    formationBuckets.limits[abbr] = starterLimit + emgLimit;
    formationBuckets.counts[abbr] = 0;
    formationBuckets.starters[abbr] = [];
    formationBuckets.emergencies[abbr] = [];
  });

  return formationBuckets;
}

export function allocatePlayers(players, allocationOrder, formation) {
  const formationBuckets = initializeFormationBuckets(formation);

  players.forEach((player) => {
    const playerCanPlay = player.positions; // Player positions from the data
    let allocated = false;

    for (const pos of allocationOrder) {
      const lineupPosition = formationBuckets.positions[pos];
      const starterLimit =
        formation.starters.find((p) => p.abbr === pos)?.limit || 0;

      if (formationBuckets.counts[pos] < formationBuckets.limits[pos]) {
        // Get eligible positions for this lineup spot
        const eligiblePositions =
          formationPositions.find((p) => p.abbr === pos)?.eligiblePlayerPos ||
          [];

        // Check if player can play in any of the eligible positions
        const canPlay = eligiblePositions.some((checkPos) =>
          playerCanPlay.includes(checkPos),
        );

        if (canPlay) {
          formationBuckets.counts[pos]++;

          // Add to starters if there's room, otherwise to emergencies
          if (formationBuckets.starters[pos].length < starterLimit) {
            formationBuckets.starters[pos].push(player);
          } else {
            formationBuckets.emergencies[pos].push(player);
          }

          allocated = true;
          break;
        }
      }
    }

    if (!allocated) {
      formationBuckets.bench.push(player);
    }
  });

  return formationBuckets;
}

export function playersByEligiblePosition(lineupFormation, players) {
  const eligiblePlayers = {};

  // Just use the starters array from lineupFormation
  for (const position of lineupFormation.starters) {
    // Get the formation position details from our master list
    const formationPos = getFormationPosById(position.abbr);

    if (!formationPos) {
      continue;
    }

    // For each player
    for (const player of players) {
      // Get positions this player can play (from leagueSeason)
      const playerCanPlay = player.positions;

      // Check if player is eligible for any of the position's eligible spots
      const isEligible = formationPos.eligiblePlayerPos.some((checkPos) =>
        playerCanPlay.includes(checkPos),
      );

      if (isEligible) {
        // Initialize array if it doesn't exist
        if (!eligiblePlayers[position.abbr]) {
          eligiblePlayers[position.abbr] = [];
        }

        // Add player to eligible list for this position
        eligiblePlayers[position.abbr].push(player);
      }
    }
  }

  return eligiblePlayers;
}

export function sortKeysByArrayLength(obj) {
  return Object.entries(obj)
    .sort((a, b) => a[1].length - b[1].length)
    .map(([key]) => key);
}

export function sortPositionsByDisplayOrder(positions) {
  return positions.sort((a, b) => {
    const posA = getFormationPosById(a.abbr);
    const posB = getFormationPosById(b.abbr);
    return (posA?.displayOrder || 0) - (posB?.displayOrder || 0);
  });
}
