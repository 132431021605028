import React, { Suspense, useEffect, useState } from "react";
import { usePubNub } from "./hooks/usePubNub";
import { useInitDraft } from "./hooks/useInitDraft";
import { useViewport } from "./hooks/useViewport";
import { DraftRoomDesktop } from "./layouts/DraftRoomDesktop";
import { DraftRoomMobile } from "./layouts/DraftRoomMobile";
import DraftRoomDialog from "./modals/DraftRoomDialog";
import { AlertNotification } from "./alerts/AlertNotification";

export default function DraftRoom({ boardId, userId, env, staticData }) {
  const { loading, error: initError } = useInitDraft({ boardId, staticData });

  const {
    connectionStatus,
    error: connectionError,
    showPickNotification,
    pickMessage,
    setShowPickNotification,
    showSuccessNotification,
    successMessage,
    setShowSuccessNotification,
  } = usePubNub({ boardId, userId, env });
  const [showConnectionNotification, setShowConnectionNotification] =
    useState(false);
  const isWide = useViewport();

  useEffect(() => {
    if (connectionStatus === "error" || connectionStatus === "disconnected") {
      setShowConnectionNotification(true);
    } else {
      setShowConnectionNotification(false);
    }
  }, [connectionStatus]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-96">
        Loading draft data...
      </div>
    );
  }

  if (initError) {
    return (
      <div className="text-red-500 p-4">
        Error loading draft data: {initError}
      </div>
    );
  }

  return (
    <>
      {showConnectionNotification && (
        <AlertNotification
          type={connectionStatus === "error" ? "error" : "error_persist"}
          message={
            connectionError || "Lost connection to draft room. Reconnecting..."
          }
          duration={connectionStatus === "error" ? 5000 : 9999999999}
          onClose={() => setShowConnectionNotification(false)}
        />
      )}
      {showPickNotification && (
        <AlertNotification
          type="success"
          message={pickMessage}
          duration={5000}
          onClose={() => setShowPickNotification(false)}
        />
      )}
      {showSuccessNotification && (
        <AlertNotification
          type="success"
          message={successMessage}
          duration={3000}
          onClose={() => setShowSuccessNotification(false)}
        />
      )}
      <Suspense
        fallback={
          <div className="flex items-center justify-center h-96">
            Loading draft room...
          </div>
        }
      >
        {isWide ? <DraftRoomDesktop /> : <DraftRoomMobile />}
        <DraftRoomDialog />
      </Suspense>
    </>
  );
}
