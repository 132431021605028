import React from "react";
import { DraftPicksList } from "./DraftResults";

export function TeamTab() {
  return (
    <div className="h-[calc(100vh-9.5rem)] lg:h-full overflow-y-auto">
      <div className="px-2 pb-24 md:pb-4">
        <DraftPicksList />
      </div>
    </div>
  );
}
