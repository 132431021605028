import useDraftStore from "../../lib/store/DraftStore";
import React from "react";
import { TeamColumn } from "./TeamColumn";

export function PicksGrid() {
  const [
    picks,
    players,
    teamsById,
    userTeamId,
    leagueSeasonId,
    leagueData,
    boardData,
    keptPlayerIdsByTeamId,
  ] = useDraftStore((state) => [
    state.picks,
    state.players,
    state.teamsById,
    state.userTeamId,
    state.leagueSeasonId,
    state.leagueData,
    state.boardData,
    state.keptPlayerIdsByTeamId,
  ]);

  const isCommish = leagueData?.isCommish ?? false;
  const boardOrder = boardData.order ?? [];

  return (
    <div id="picks-grid" className="inline-flex gap-x-0.5 w-full px-1 mb-4">
      {boardOrder.map((boardTeamId) => (
        <TeamColumn
          key={boardTeamId}
          boardTeamId={boardTeamId}
          team={teamsById[boardTeamId]}
          isUserTeam={boardTeamId === userTeamId}
          picks={picks}
          players={players}
          isCommish={isCommish}
          leagueSeasonId={leagueSeasonId}
          keptPlayerIds={keptPlayerIdsByTeamId[boardTeamId] ?? []}
        />
      ))}
    </div>
  );
}
