export function getTableColByKey(key) {
  return tableCols.find((col) => col.key === key);
}

const tableCols = [
  {
    key: "points",
    field: "Fan Pts",
    name: "Fantasy Points",
    sortParam: "splits.avgPoints",
  },
  {
    key: "togPct",
    field: "TOG%",
    name: "Time On Ground %",
    sortParam: "splits.avgTog",
  },
  {
    key: "games",
    field: "GP",
    name: "Games Played",
    sortParam: "splits.gamesPlayed",
  },
  { key: "total", field: "Tot.", name: "Total", sortParam: "splits.total" },
  {
    key: "score",
    field: "SCORE",
    name: "Goals & Behinds",
    sortParam: "splits.avgGoals",
  },
  {
    key: "goalAccuracy",
    field: "G%",
    name: "Goal Accuracy",
    sortParam: "splits.goalAccuracy",
  },
  { key: "goals", field: "GL", name: "Goals", sortParam: "splits.avgGoals" },
  {
    key: "behinds",
    field: "BH",
    name: "Behinds",
    sortParam: "splits.avgBehinds",
  },
  { key: "kicks", field: "KI", name: "Kicks", sortParam: "splits.avgKicks" },
  {
    key: "handballs",
    field: "HB",
    name: "Handballs",
    sortParam: "splits.avgHandballs",
  },
  { key: "marks", field: "MA", name: "Marks", sortParam: "splits.avgMarks" },
  {
    key: "hitouts",
    field: "HO",
    name: "Hitouts",
    sortParam: "splits.avgHitouts",
  },
  {
    key: "hitoutsAdv",
    field: "HO+",
    name: "Hitouts to Advantage",
    sortParam: "splits.avgHitoutsAdv",
  },
  {
    key: "tackles",
    field: "TK",
    name: "Tackles",
    sortParam: "splits.avgTackles",
  },
  {
    key: "freesFor",
    field: "FF",
    name: "Frees For",
    sortParam: "splits.avgFreesFor",
  },
  {
    key: "freesAgainst",
    field: "FA",
    name: "Frees Against",
    sortParam: "splits.avgFreesAgainst",
  },
  {
    key: "freesDiff",
    field: "FD",
    name: "Free Kick Differential",
    sortParam: "splits.avgFreesDiff",
  },
  {
    key: "disposals",
    field: "D",
    name: "Disposals",
    sortParam: "splits.avgDisposals",
  },
  {
    key: "effectiveDisposals",
    field: "ED",
    name: "Effective Disposals",
    sortParam: "splits.avgEffectiveDisposals",
  },
  {
    key: "ineffectiveDisposals",
    field: "ID",
    name: "Ineffective Disposals",
    sortParam: "splits.avgIneffectiveDisposals",
  },
  {
    key: "disposalEfficiency",
    field: "D%",
    name: "Disposal Efficiency",
    sortParam: "splits.disposalEfficiency",
  },
  {
    key: "contestedPossessions",
    field: "CP",
    name: "Contested Possessions",
    sortParam: "splits.avgContestedPossessions",
  },
  {
    key: "uncontestedPossessions",
    field: "UP",
    name: "Uncontested Possessions",
    sortParam: "splits.avgUncontestedPossessions",
  },
  {
    key: "totalPossessions",
    field: "P",
    name: "Total Possessions",
    sortParam: "splits.avgTotalPossessions",
  },
  {
    key: "inside50s",
    field: "I50",
    name: "Inside 50s",
    sortParam: "splits.avgInside50s",
  },
  {
    key: "marksInside50",
    field: "M50",
    name: "Marks Inside 50",
    sortParam: "splits.avgMarksInside50",
  },
  {
    key: "contestedMarks",
    field: "CM",
    name: "Contested Marks",
    sortParam: "splits.avgContestedMarks",
  },
  {
    key: "goalAssists",
    field: "GA",
    name: "Goal Assists",
    sortParam: "splits.avgGoalAssists",
  },
  {
    key: "rebound50s",
    field: "R50",
    name: "Rebound 50s",
    sortParam: "splits.avgRebound50s",
  },
  {
    key: "onePercenters",
    field: "1%",
    name: "One Percenters",
    sortParam: "splits.avgOnePercenters",
  },
  { key: "spoils", field: "SP", name: "Spoils", sortParam: "splits.avgSpoils" },
  {
    key: "clangers",
    field: "CG",
    name: "Clangers",
    sortParam: "splits.avgClangers",
  },
  {
    key: "turnovers",
    field: "TO",
    name: "Turnovers",
    sortParam: "splits.avgTurnovers",
  },
  {
    key: "bounces",
    field: "B",
    name: "Running Bounces",
    sortParam: "splits.avgBounces",
  },
  {
    key: "clearances",
    field: "CL",
    name: "Clearances",
    sortParam: "splits.avgClearances",
  },
];
